/* eslint-disable no-underscore-dangle */
import merge from 'lodash/merge';

type Sidecar = {
  __sidecarFormatVersion: number;
  applicationId: string;
  applicationName: string;
  appsApiBaseUrl: string;
  cdfApiBaseUrl: string;
  docsSiteBaseUrl: string;
  nomaApiBaseUrl: string;
  aadApplicationId: string;
};

let cdfApiUrl = ` https://omv.cognitedata.com`;

if (process.env.REACT_APP_ENV === 'mock') {
  cdfApiUrl = 'https://localhost:3000';
}
if (process.env.REACT_APP_ENV === 'testcafe') {
  cdfApiUrl = 'http://localhost:11111';
}

const sidecar = merge(
  {},
  {
    __sidecarFormatVersion: 1,
    applicationId: 'isolation-planning',
    applicationName: 'Isolation Planning',
    appsApiBaseUrl: 'https://apps-api.omv.cognite.ai',
    privacyPolicyUrl: 'https://www.cognite.com/en/policy',
    cdfApiBaseUrl: cdfApiUrl,
    disableLegacyLogin: true,
    enableUserManagement: false,
    docsSiteBaseUrl: 'https://docs.cognite.com',
    cdfCluster: 'omv',
    userManagementServiceBaseUrl:
      'https://user-management-service.omv.cognite.ai',
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line no-underscore-dangle
  (window as any).__cogniteSidecar
) as Sidecar;

export default sidecar;
